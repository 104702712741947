<template>
    <div class="mypage">
        <el-pagination
            background
            @current-change="currentChange"
            @size-change="sizeChange"
            :page-size="10"
            :current-page="page"
            layout="total,prev, pager, next, sizes, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name:'tablePage',
    props:['total','page','size'],
    data(){
        return{

        }
    },
    methods:{
        currentChange(val){
            this.$emit('getTableData',val)
        },
        sizeChange(val){
            this.$emit('update:size',val)
        }
    }
}
</script>

<style lang="scss">
.mypage{
    margin-top: 30px;
    position: absolute;
    right: 30px;
    bottom: 30px;
    .el-pagination{
        font-size: 14px;
        .el-select .el-input .el-input__inner,.el-input__inner{
             border-radius: 6px;
             color: #AEAEAE!important;
             border: 1px solid #454545;
        }
        .btn-prev, .btn-next,.el-pager li{
            border-radius: 6px!important;
        }
        button{
            border: 1px solid #454545;
            color: #AEAEAE!important
        }
        .el-pager li.number{
            border: 1px solid #454545;
            color: #AEAEAE;
        }
        .el-pager li.active{
            color: #FFFFFF!important
        }
        .el-pagination__jump{
            color: #AEAEAE!important;
            margin-left: 0!important;
        }
        .el-pagination__total{
            color: #AEAEAE!important;
        }
    }
}
</style>